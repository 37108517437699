// @ts-nocheck
import React, { useEffect } from "react";
import PseudoRouteForm from "components/psuedo-route-form";
import { useFetchPseudoRouteByNameQuery } from "generated/graphql";
import { useHistory } from "react-router-dom";
import { client } from "api";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import { formatError } from "utils";

const labels = {
  weight: "Weight",
  max_shops: "Max Shops"
};

const EditPseudoRoute = () => {
  const [defaultValues, setDefaultValues] = React.useState<any>();
  const [httpError, setHttpError] = React.useState<any>(null);
  const [loadingState, setLoading] = React.useState(false);

  const history = useHistory();
  const name = history.location.pathname.split("/").pop();

  const { data: psuedoRouteData, loading } = useFetchPseudoRouteByNameQuery({
    variables: {
      name: name as string
    }
  });

  useEffect(() => {
    if (psuedoRouteData?.pseudo_route_by_name) {
      const defaultVal: any = {
        name: psuedoRouteData.pseudo_route_by_name.name,
        constraints: psuedoRouteData.pseudo_route_by_name.constraints.map(
          constraint => ({
            value: constraint,
            label: labels[constraint as keyof typeof labels]
          })
        ),
        routes: psuedoRouteData.pseudo_route_by_name.routes.map(route => ({
          value: route?.route_id,
          label: route?.route_name
        }))
      };

      if (psuedoRouteData.pseudo_route_by_name.constraints.includes("weight")) {
        defaultVal["constraint_weight"] = { label: "Weight", value: "weight" };
        defaultVal["weight_constraint_value"] = JSON.parse(
          psuedoRouteData.pseudo_route_by_name.custom_constraints
        ).weight;
      }
      if (
        psuedoRouteData.pseudo_route_by_name.constraints.includes("max_shops")
      ) {
        defaultVal["max_shops_constraint"] = {
          label: "Max Shops",
          value: "max_shops"
        };
        defaultVal["max_shops_constraint_value"] = JSON.parse(
          psuedoRouteData.pseudo_route_by_name.custom_constraints
        ).max_shops;
      }

      setDefaultValues(defaultVal);
    }
  }, [psuedoRouteData]);

  const onSubmit = (data: any) => {
    const payload = {
      name: data.name,
      merged_routes: data.routes.map((route: any) => route.value),
      constraints:
        data.weight_constraint_value?.length > 0 &&
        data.max_shops_constraint_value?.length > 0
          ? ["weight", "max_shops"]
          : data.weight_constraint_value?.length > 0
          ? ["weight"]
          : ["max_shops"],
      custom_constraints:
        data.weight_constraint_value?.length > 0 &&
        data.max_shops_constraint_value?.length > 0
          ? {
              weight: data.weight_constraint_value,
              max_shops: data.max_shops_constraint_value
            }
          : data.weight_constraint_value?.length > 0
          ? {
              weight: data.weight_constraint_value
            }
          : {
              max_shops: data.max_shops_constraint_value
            },
      force_share: false,
      client_stamp: uuidv4()
    };
    setLoading(true);
    client
      .put(
        `/pseudo-routes/${psuedoRouteData?.pseudo_route_by_name?.pseudo_route_id}`,
        payload
      )
      .then(() => {
        history.push("/admin/pseudo-routes");
        toast.success("Pseudo Updated successfully");
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        setHttpError(formatError(error));
      });
  };

  return (
    <div>
      {loading && !!defaultValues ? (
        <div>Loading...</div>
      ) : (
        <PseudoRouteForm
          loading={loadingState}
          defaultValues={defaultValues}
          httpError={httpError}
          onSubmit={data => onSubmit(data)}
        />
      )}
    </div>
  );
};

export default EditPseudoRoute;
