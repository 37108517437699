import React, { useState, useEffect } from "react";
import { css } from "@emotion/react";
import { AddButton, Holder, Input, Actions, ActionBtn } from "./styles";
import cancelBtn from "assets/cell-btn-cancel.svg";
import updateBtn from "assets/cell-btn-update.svg";
import PropTypes from "prop-types";
import { formatNumber } from "utils";
import styled from "@emotion/styled";
import EditIcon from "assets/edit2.svg";

const EditableCell = ({
  value: initialValue = "",
  updateHandler,
  name = ""
}) => {
  const [value, setvalue] = useState("");
  const [isAdding, setAdding] = useState(false);

  useEffect(() => {
    setvalue(initialValue);
  }, [initialValue]);

  const handleUpdate = () => {
    updateHandler(value, () => setAdding(false));
  };

  const EditIconWrapper = styled.img`
    ${({ disabled = false }) => css`
      width: 30px;
      height: 30px;
      padding: 4px;
      cursor: ${disabled ? "not-allowed" : "pointer"};
    `}
  `;

  const FlexEdit = styled.div`
    display: flex;
    align-items: center;
  `;

  const EditWrapperSpan = styled.span`
    margin-left: 2px;
  `;

  const checkInitialValue = initialValue === null || initialValue === undefined;

  return (
    <div>
      {!checkInitialValue && !isAdding && (
        <FlexEdit>
          {value === "-" ? (
            <span onClick={() => setAdding(true)}>
              <EditIconWrapper src={EditIcon} alt="edit" />
            </span>
          ) : (
            <FlexEdit>
              <span className="value" onClick={() => setAdding(true)}>
                {formatNumber(value)} {name}
              </span>
              <EditWrapperSpan onClick={() => setAdding(true)}>
                <EditIconWrapper src={EditIcon} alt="edit" />
              </EditWrapperSpan>
            </FlexEdit>
          )}
        </FlexEdit>
      )}
      {checkInitialValue && !isAdding && (
        <AddButton onClick={() => setAdding(true)}>+add</AddButton>
      )}
      {isAdding && (
        <Holder>
          <Input
            value={value || ""}
            type="number"
            onChange={({ target }) => setvalue(target.value)}
          />

          <Actions>
            <ActionBtn onClick={handleUpdate}>
              <img src={updateBtn} alt="Update record" title="Update record" />
            </ActionBtn>
            <ActionBtn onClick={() => setAdding(false)}>
              <img src={cancelBtn} alt="Cancel update" title="Cancel update" />
            </ActionBtn>
          </Actions>
        </Holder>
      )}
    </div>
  );
};

EditableCell.defaultProps = {
  updateHandler: value => console.log("new value is", value)
};

EditableCell.propTypes = {
  updateHandler: PropTypes.func.isRequired
};

export default EditableCell;
