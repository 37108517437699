import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const SubRowTable = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    background-color: ${theme.colors.accent};
    color: ${theme.colors.black};
    margin-left: 0;
    padding: 15px 50px;

    .transaction-state-label {
      &.PAID {
        border-left: 5px solid ${theme.colors.green};
      }

      &.INPROGRESS {
        border-left: 5px solid ${theme.colors.darkBlue};
      }

      &.FAILED {
        border-left: 5px solid ${theme.colors.notifications.error};
      }
    }
    .tranfer-state {
      &.true {
        border-left: 5px solid ${theme.colors.notifications.error};
      }
    }

    table {
      max-width: 1020px;
      background-color: ${theme.colors.accent};
      border-collapse: collapse;

      td {
        padding: 0 0px;
      }

      thead {
        background-color: ${theme.colors.accent};
        tr {
          border-bottom: 1px solid ${theme.colors.greys[500]};
          border-width: thin;
        }
      }

      tbody {
        tr {
          border-bottom: 1px solid ${theme.colors.greys[500]};
        }
        td {
          padding: 6px 8px;
          :first-of-type {
            padding: 0 7px;
          }
        }
      }
    }

    span {
      padding: 10px 5px;
      text-decoration: underline;
      color: ${theme.colors.greys[500]};
      font-size: 14px;
      font-weight: ${theme.fontWeights.semiBold};
      cursor: pointer;
      a {
        color: inherit;
      }
    }

    .cancellation-reason {
      height: 43px;
      background: ${theme.colors.background3};
      border-radius: 4px;
      text-align: left;
      font-weight: ${theme.fontWeights.semiBold};
      font-size: 14px;
      color: ${theme.colors.notifications.error};
      padding: 12px 0 12px 20px;
      margin-bottom: 5px;
    }
  `}
`;
